<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-sm-6">
            <app-button-edit
              v-if="dataLoaded"
              route-name="printPublication_edit"
              :record-id="printPublication.id"
            >
            </app-button-edit>
          </div>
          <div class="col-sm-6">
            <div class="text-right m-r-5">
              <app-button-delete v-if="dataLoaded" @deleteRecord="deletePrintPublication"></app-button-delete>
              <app-button-close route-name="printPublication_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div v-if="dataLoaded" class="card">
        <div class="card-body">
          <table class="table table-striped table-bordered">
            <tbody>
            <tr>
              <th>Id</th>
              <td>{{ printPublication.id }}</td>
            </tr>
            <tr>
              <th>{{ $t('printPublication.title') }}</th>
              <td>{{ printPublication.title }}</td>
            </tr>
            <tr>
              <th>{{ $t('printPublication.slug') }}</th>
              <td>{{ printPublication.slug }}</td>
            </tr>
            <tr>
              <th>{{ $t('printPublication.title_title') }}</th>
              <td>{{ printTitleById(printPublication.printTitle) }}</td>
            </tr>
            <tr>
              <th>{{ $t('printPublication.publication_date') }}</th>
              <td>{{ printPublication.publicationDate|prettyDate }}</td>
            </tr>
            <tr>
              <th>{{ $t('printPublication.description') }}</th>
              <td v-html="printPublication.description"></td>
            </tr>
            <tr>
              <th>{{ $t('printPublication.image') }}</th>
              <td>
                <app-media
                  v-if="printPublication.image"
                  :media="printPublication.imageInfo"
                  :width="448"
                  :height="0"
                  show-media-info
                >
                </app-media>
              </td>
            </tr>
            </tbody>
          </table>
          <app-detail-system-rows :record="printPublication"></app-detail-system-rows>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import DetailSystemRows from '../shared/DetailSystemRows'
import ButtonEdit from '../shared/ButtonEdit'
import PrintPublicationModel from '../../model/PrintPublication'
import ButtonDelete from '../shared/ButtonDelete'
import ButtonClose from '../shared/ButtonClose'
import NotifyService from '../../services/NotifyService'
import Media from '../shared/Media'

export default {
  name: 'PrintPublication',
  data () {
    return {
      printPublication: PrintPublicationModel,
      dataLoaded: false
    }
  },
  components: {
    appModuleView: ModuleView,
    appButtonEdit: ButtonEdit,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appDetailSystemRows: DetailSystemRows,
    appMedia: Media
  },
  methods: {
    deletePrintPublication () {
      this.$store.dispatch('printPublication/deleteRecord', this.printPublication)
        .then(() => {
          if (this.$store.getters['printPublication/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/printPublication')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['printPublication/error'])
          }
        })
        .catch(error => console.log(error))
    },
    getPrintPublication () {
      this.$store.dispatch('printTitle/fetchAll')
        .then(() => {
          this.$store.dispatch('printPublication/fetchOne', this.$route.params.id)
            .then(() => {
              this.printPublication = this.$store.getters['printPublication/detail']
              this.dataLoaded = true
            })
        })
    },
    printTitleById (id) {
      const item = this.$store.getters['printTitle/printTitleById'](parseInt(id))
      if (item) {
        return item.title
      }
      return id
    }
  },
  created () {
    this.getPrintPublication()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.table-bordered th:first-child {
  width: 200px;
}
</style>
